<template>
  <div
    class="rounded-[25px] relative p-3 mb-4 flex items-center justify-between border border-[#806157]"
  >
    <div class="appProfileAdress__info">
      <div class="appProfileAdress__info__title">
        <span v-if="isDefault"
      class="star pb-[2px] px-[4px]"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 24 24"
      >
        <path
          fill="#01a4c0"
          d="m5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22Z"
        /></svg
    ></span>
        {{ type }}
      </div>
      <div class="appProfileAdress__info__text">
        {{ adress }}
      </div>
    </div>
    <div
      class="rounded-full p-1 cursor-pointer"
      @click="$emit('delete')"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#EC6C6B" d="M7 21q-.825 0-1.413-.588T5 19V6q-.425 0-.713-.288T4 5q0-.425.288-.713T5 4h4q0-.425.288-.713T10 3h4q.425 0 .713.288T15 4h4q.425 0 .713.288T20 5q0 .425-.288.713T19 6v13q0 .825-.588 1.413T17 21H7Zm5-7.1l1.9 1.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7l-1.9-1.9l1.9-1.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 11.1l-1.9-1.9q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l1.9 1.9l-1.9 1.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275l1.9-1.9Z"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: "",
      type: String,
    },
    adress: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
    isDefault: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.appProfileAdress {
  width: 100%;
  border-bottom: 2px dashed #e5e5e5;
  // border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  margin-bottom: 10px;

  &__house {
    max-height: 50px;
    max-width: 50px;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
    margin: 20px;
  }

  &__info {
    &__title {
      display: flex;
      align-items: center;
      color: var(--secondary);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; margin-bottom: 10px;
    }

    &__text {
      font-size: 16px;
      line-height: 21px;
      color: #9E9E9E;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px;
      white-space: nowrap;
    }
  }
}
.addressEdit {
  position: absolute;
  background-color: #36d5f2;
  top: -10px;
  left: -50px;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-image: url("../assets/images/edit.svg");
  background-position: center;
  background-size: 25px;
  background-repeat: no-repeat;
  margin-left: 93%;
  //margin-top: -140px;
  cursor: pointer;
}
.addressDelete {
  position: absolute;
  // background-color: #ff4f79;
  top: 10px;
  right: 10px;
  // width: 25px;
  font-size: 12px;
  font-weight: 600;
  color: var(--red);
  padding: 0 13px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 3px;
  }
}
.isDefault {
  font-size: 11px;
  background: var(--main);
  color: white;
  height: 20px;
  width: 110px;
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  font-weight: 700;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
